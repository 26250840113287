import Vue from 'vue';
import axios from 'axios';
import VueGtag from 'vue-gtag';
import nProgress from 'nprogress';
import VueCookies from 'vue-cookies';
import router from './router';
import App from './App.vue';

/** Variables */
const idTracking = 'G-X33ZCNWM40';

/** Vue middlewhare. */
Vue.use(VueCookies);
Vue.use(VueGtag, {
    config: { id: idTracking },
}, router);

/** Set vue prorotype. */
Vue.prototype.$vue = Vue;
Vue.prototype.$axios = axios;
Vue.prototype.$nProgress = nProgress;

new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
