import Vue from 'vue';
import VueRouter from 'vue-router';
import nProgress from 'nprogress';

/** Components. */
import AuthEmployee from './views/AuthEmployee.vue';
import AuthAdmin from './views/AuthAdmin.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/', redirect: { name: 'authEmployee' },
    },
    {
        path: '/auth', redirect: { name: 'authEmployee' },
    },
    {
        path: '/auth/employee',
        name: 'authEmployee',
        component: AuthEmployee,
    },
    {
        path: '/auth/admin',
        name: 'authAdmin',
        component: AuthAdmin,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    nProgress.start();
    nProgress.set(0.5);
    next();
});
router.afterEach(() => {
    setTimeout(() => nProgress.done(), 20);
});

export default router;
