<template>
    <router-view />
</template>

<script>
    export default {

        beforeMount() {
            //
        },

        mounted() {
            document.querySelector('.loading').classList.add('d-none');
        },

        data() {
            return {
                //
            };
        },

        components: {
            //
        },
    };
</script>