<template>
    <div class="login_wrapper">
        <div class="animate form login_form">
            <section class="login_content">
                <form @submit.prevent="login()" autocomplete="off">
                    <h1>Employee Access</h1>
                    <div>
                        <input v-model="form.data.nik" type="text" class="form-control" placeholder="NIK" required="">
                    </div>
                    <div>
                        <input v-model="form.data.password" type="password" class="form-control" placeholder="Password" required="">
                    </div>
                    <div class="text-right">
                        <button :disabled="!form.button" type="submite" class="btn btn-secondary btn-block">Login</button>
                    </div>
                    <p v-if="form.message" class="text-danger mb-1 text-left">
                        <span><i>{{ form.message }}</i></span>
                    </p>
                    <div class="clearfix"></div>
                    <div class="separator">
                         <p class="change_link text-right">
                            <router-link :to="{name: 'authAdmin'}">Administrator Access</router-link>
                        </p>
                        <br>
                        <p>Copyright © {{ year }} Docs Archive</p>
                    </div>
                </form>
            </section>
        </div>
    </div>
</template>

<script>
export default {

    methods: {
        login() {
            this.form.message = null;
            this.form.button = false;
            this.$axios.post('/api/guest/login/employee?login=true', this.form.data).then((res) => {
                if (res.data.success) {
                    document.location.href = '/employee';
                }
                this.form.button = true;
                this.form.message = res.data.message;
            });
        },
    },

    beforeMount() {
        //
    },

    data() {
        return {
            form: {
                data: {
                    nik: null,
                    password: null,
                },
                button: true,
                message: null,
            },
            year: new Date().getUTCFullYear(),
        };
    },

    components: {
        //
    },
};
</script>
